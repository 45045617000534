import Link from "next/link";
import { formatSalonUrl } from "@/utils/formatSalonUrl";

interface Event {
  title: string;
  description: string;
  period: string;
  salons: string[];
  promoCode: string;
}

interface PromoCodeTemplateProps {
  event: Event;
}

export const PromoCodeTemplate = ({ event }: PromoCodeTemplateProps) => {
  const midPoint = Math.ceil(event.salons.length / 2);
  const firstRow = event.salons.slice(0, midPoint);
  const secondRow = event.salons.slice(midPoint);

  const renderSalonRow = (salons: string[]) => (
    <p className="flex justify-center gap-2">
      {salons.map((salon, idx) => (
        <span className="flex items-center gap-2" key={idx}>
          {idx > 0 && <span className="text-white/80">• </span>}
          <Link
            href={formatSalonUrl(salon)}
            className="text-xs tracking-[0.1em] text-white/80 transition-colors hover:text-gold"
          >
            {salon}
          </Link>
        </span>
      ))}
    </p>
  );

  return (
    <div className="flex h-[500px] w-[600px] flex-col items-center justify-between bg-[#1C1C1C] p-12 text-white">
      <div className="flex flex-col items-center justify-center text-center">
        <span className="text-md mb-2 uppercase tracking-[0.2em] text-white">
          4 salons · 1 vision
        </span>

        <h1 className="mb-[-0.5rem] font-cormorant text-4xl uppercase tracking-[0.2em] text-white">
          Coiffure & Visagiste
        </h1>
        <span className="mb-8 font-pencerio text-3xl text-gold">
          Alexandra Grey
        </span>
      </div>

      <div className="flex flex-col items-center justify-center text-center">
        <p className="text-sm uppercase tracking-[0.2em]">{event.title}</p>
        <p className="mt-4 text-5xl font-light tracking-[0.2em] text-gold">
          {event.promoCode}
        </p>
        <p className="mt-4 text-sm tracking-[0.1em] text-white">
          {event.period}
        </p>
      </div>

      <div className="text-center">
        <p className="text-sm uppercase tracking-[0.2em] text-white/90">
          Valable dans les salons
        </p>
        <div className="mt-4 flex flex-col gap-1">
          {renderSalonRow(firstRow)}
          {secondRow.length > 0 && renderSalonRow(secondRow)}
        </div>
      </div>
    </div>
  );
};
