"use client";

import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";
import { PromoCodeModal } from "./PromoCodeModal";
import { formatSalonUrl } from "@/utils/formatSalonUrl";

interface Event {
  title: string;
  subtitle?: string;
  description: string;
  image: string;
  period: string;
  salons: string[];
  promoCode: string;
}

export const MultipleEventsLayout = ({ events }: { events: Event[] }) => {
  const [isMobile, setIsMobile] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 1024);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const renderSalons = (salons: string[]) => (
    <div className="flex flex-wrap gap-2">
      {salons.map((salon, idx) => (
        <Link
          key={idx}
          href={formatSalonUrl(salon)}
          className="text-sm underline transition-colors hover:text-gold"
        >
          {salon}
        </Link>
      ))}
    </div>
  );

  return (
    <section className="flex w-full flex-col gap-[3rem] bg-primary px-4 py-20 text-white ph:px-24 md:gap-0 md:px-48 lg:px-72 xl:px-96">
      <div>
        <h2 className="mb-2 font-cormorant text-4xl ph:text-5xl sm:text-6xl md:mb-12 lg:mb-16 lg:text-7xl">
          ÉVÈNEMENTS
        </h2>
      </div>

      <div className="w-full">
        {events.length === 2 ? (
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
            {events.map((event, index) => (
              <div key={index} className="flex flex-col gap-4">
                <div
                  className="relative w-full overflow-hidden"
                  style={{ aspectRatio: "16/9" }}
                >
                  <Image
                    src={event.image}
                    alt={event.title}
                    layout="responsive"
                    width={800}
                    height={450}
                    className="w-full object-cover"
                  />
                </div>
                <h3 className="text-2xl">{event.title}</h3>
                <p className="text-sm">{event.period}</p>
                <p className="text-base">{event.description}</p>

                <div className="mt-4">
                  <p className="text-md mb-2">SALONS CONCERNÉS</p>
                  {renderSalons(event.salons)}
                </div>

                <div className="mt-auto">
                  <div
                    className="group relative inline-block w-fit cursor-pointer pb-[2px]"
                    onClick={() => {
                      setSelectedEvent(event);
                      setIsModalOpen(true);
                    }}
                  >
                    <span>Obtenir mon code promo</span>
                    <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
                      <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : events.length === 4 ? (
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
            {events.map((event, index) => (
              <div key={index} className="flex flex-col gap-4">
                <div
                  className="relative w-full overflow-hidden"
                  style={{ aspectRatio: "16/9" }}
                >
                  <Image
                    src={event.image}
                    alt={event.title}
                    layout="responsive"
                    width={800}
                    height={450}
                    className="w-full object-cover"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <h3 className="text-2xl">{event.title}</h3>
                  <p className="text-sm">{event.period}</p>
                  <p className="text-sm">{event.description}</p>

                  <div className="mt-2">
                    <p className="mb-1 text-sm">SALONS CONCERNÉS</p>
                    {renderSalons(event.salons)}
                  </div>

                  <div className="mt-auto">
                    <div
                      className="group relative inline-block w-fit cursor-pointer pb-[2px]"
                      onClick={() => {
                        setSelectedEvent(event);
                        setIsModalOpen(true);
                      }}
                    >
                      <span>Obtenir mon code promo</span>
                      <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
                        <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col gap-8 lg:flex-row lg:gap-6">
            <div className="w-full lg:w-5/12 xl:w-6/12">
              <div className="flex flex-col gap-2 sm:gap-4">
                <div
                  className="relative w-full overflow-hidden"
                  style={{ aspectRatio: "16/9" }}
                >
                  <Image
                    src={events[0].image}
                    alt={events[0].title}
                    layout="responsive"
                    width={800}
                    height={450}
                    className="w-full object-cover"
                  />
                </div>
                <h3 className="text-2xl">{events[0].title}</h3>
                <p className="text-sm">{events[0].period}</p>
                <p className="line-clamp-4 text-base xl:line-clamp-none">
                  {events[0].description}
                </p>

                <div className="mt-4">
                  <p className="mb-2 text-sm">SALONS CONCERNÉS</p>
                  {renderSalons(events[0].salons)}
                </div>

                <div className="mt-auto">
                  <div
                    className="group relative inline-block w-fit cursor-pointer pb-[2px]"
                    onClick={() => {
                      setSelectedEvent(events[0]);
                      setIsModalOpen(true);
                    }}
                  >
                    <span>Obtenir mon code promo</span>
                    <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
                      <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col gap-8 lg:w-7/12 lg:gap-6 xl:w-6/12">
              {events.slice(1, 3).map((event, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-4 lg:flex-row lg:gap-4"
                >
                  <div
                    className="relative w-full overflow-hidden lg:w-1/2"
                    style={{
                      aspectRatio: isMobile ? "16/9" : "3/4",
                    }}
                  >
                    <Image
                      src={event.image}
                      alt={event.title}
                      fill
                      className="h-full w-full object-cover"
                      sizes="(max-width: 1024px) 100vw, 33vw"
                    />
                  </div>
                  <div className="flex flex-col gap-2 lg:w-1/2">
                    <h3 className="text-xl">{event.title}</h3>
                    <p className="text-sm">{event.period}</p>
                    <p className="line-clamp-3 text-sm lg:line-clamp-4">
                      {event.description}
                    </p>

                    <div>
                      <p className="mb-1 text-sm">SALONS CONCERNÉS</p>
                      {renderSalons(event.salons)}
                    </div>

                    <div
                      className="group relative inline-block w-fit cursor-pointer pb-[2px]"
                      onClick={() => {
                        setSelectedEvent(event);
                        setIsModalOpen(true);
                      }}
                    >
                      <span>Obtenir mon code promo</span>
                      <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
                        <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <PromoCodeModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        event={selectedEvent!}
      />
    </section>
  );
};
