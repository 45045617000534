"use client";

import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { PromoCodeModal } from "./PromoCodeModal";
import { formatSalonUrl } from "@/utils/formatSalonUrl";

interface Event {
  title: string;
  description: string;
  image: string;
  period: string;
  salons: string[];
  promoCode: string;
}

export const SingleEventLayout = ({ event }: { event: Event }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <section className="flex w-full flex-col gap-8 bg-primary px-4 py-20 text-white ph:px-24 md:flex-row md:px-48 lg:px-72 xl:px-96">
      <div className="relative aspect-[16/9] w-full md:w-1/2">
        <Image
          src={event.image}
          alt={event.title}
          fill
          sizes="(max-width: 768px) 100vw, 50vw"
          className="object-cover"
        />
      </div>

      <div className="flex flex-col gap-4 md:w-1/2 lg:gap-8">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <p className="font-cormorant text-xl md:text-2xl">
              ÉVÈNEMENT DU MOIS
            </p>
            <p className="text-4xl md:text-6xl">{event.title}</p>
            <p className="text-sm">{event.period}</p>
            <p className="text-base">{event.description}</p>
          </div>

          <div>
            <p className="text-md mb-2">SALONS CONCERNÉS</p>
            <div className="flex flex-wrap gap-2">
              {event.salons.map((salon, idx) => (
                <Link
                  key={idx}
                  href={formatSalonUrl(salon)}
                  className="text-sm underline transition-colors hover:text-gold"
                >
                  {salon}
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div>
          <div
            className="group relative inline-block cursor-pointer pb-[2px]"
            onClick={() => setIsModalOpen(true)}
          >
            <span>Obtenir mon code promo</span>
            <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
              <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
            </div>
          </div>
        </div>
      </div>

      <PromoCodeModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        event={event}
      />
    </section>
  );
};
