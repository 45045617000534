"use client";

import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PromoCodeTemplate } from "./PromoCodeTemplate";

interface Event {
  title: string;
  description: string;
  period: string;
  salons: string[];
  promoCode: string;
}

interface PromoCodeModalProps {
  isOpen: boolean;
  closeModal: () => void;
  event: Event;
}

const usePreventScroll = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOpen]);
};

export const PromoCodeModal = ({
  isOpen,
  closeModal,
  event,
}: PromoCodeModalProps) => {
  usePreventScroll(isOpen);
  const promoCardRef = useRef<HTMLDivElement>(null);
  const templateRef = useRef<HTMLDivElement>(null);
  const [downloadType, setDownloadType] = useState<"none" | "pdf" | "png">(
    "none",
  );

  const downloadAsPNG = async () => {
    if (!templateRef.current) return;
    setDownloadType("png");

    try {
      const canvas = await html2canvas(templateRef.current, {
        scale: 2, // Meilleure qualité
        backgroundColor: "#1C1C1C",
      });
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = `code-promo-${event.promoCode}.png`;
      link.click();
    } finally {
      setDownloadType("none");
    }
  };

  const downloadAsPDF = async () => {
    if (!templateRef.current) return;
    setDownloadType("pdf");

    try {
      const canvas = await html2canvas(templateRef.current, {
        scale: 2, // Meilleure qualité
        backgroundColor: "#1C1C1C",
      });
      const imageData = canvas.toDataURL("image/png");

      // A5 format pour un meilleur rendu
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a5",
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(imageData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`code-promo-${event.promoCode}.pdf`);
    } finally {
      setDownloadType("none");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div className="absolute inset-0 bg-black/25" onClick={closeModal} />

      <div className="relative w-full max-w-[90%] bg-white p-4 shadow-xl sm:max-w-md sm:p-6">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">
            Votre code promo
          </h3>
          <button
            type="button"
            className="text-2xl text-gray-400 hover:text-gray-500"
            onClick={closeModal}
          >
            ×
          </button>
        </div>

        <div ref={promoCardRef} className="mt-4 bg-white">
          <p className="text-sm text-gray-500">
            Utilisez ce code lors de votre prochaine visite :
          </p>
          <div className="mt-2 flex items-center justify-center">
            <span className="text-2xl font-bold text-primary">
              {event.promoCode}
            </span>
          </div>
        </div>

        <div className="mt-4">
          <p className="mb-2 text-sm text-gray-500">
            Télécharger le code promo :
          </p>
          <div className="flex gap-2">
            <button
              type="button"
              className="flex-1 bg-primary px-4 py-2 text-base font-medium text-white hover:bg-primary/90 disabled:opacity-50"
              onClick={downloadAsPNG}
              disabled={downloadType !== "none"}
            >
              {downloadType === "png" ? (
                <span className="flex items-center justify-center">
                  <svg
                    className="mr-2 h-4 w-4 animate-spin"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  Téléchargement...
                </span>
              ) : (
                "PNG"
              )}
            </button>
            <button
              type="button"
              className="flex-1 bg-primary px-4 py-2 text-base font-medium text-white hover:bg-primary/90 disabled:opacity-50"
              onClick={downloadAsPDF}
              disabled={downloadType !== "none"}
            >
              {downloadType === "pdf" ? (
                <span className="flex items-center justify-center">
                  <svg
                    className="mr-2 h-4 w-4 animate-spin"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  Téléchargement...
                </span>
              ) : (
                "PDF"
              )}
            </button>
          </div>
        </div>

        <div className="mt-4">
          <button
            type="button"
            className="group relative inline-block w-full overflow-hidden border border-black px-8 py-2"
            onClick={closeModal}
          >
            <span className="relative z-10 text-primary transition-colors group-hover:text-white">
              Fermer
            </span>
            <div className="absolute bottom-0 left-0 h-full w-0 bg-primary transition-all duration-300 ease-out group-hover:w-full"></div>
          </button>
        </div>

        {/* Template caché pour le téléchargement */}
        <div className="fixed left-[-9999px] top-[-9999px]" ref={templateRef}>
          <PromoCodeTemplate event={event} />
        </div>
      </div>
    </div>
  );
};
